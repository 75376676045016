import Ad from './Components/Ad';
import { useEffect, useState } from 'react';
import IAd from './Components/Ad/interface';
import { useLocation } from 'react-router-dom';

export default function App() {
  const [currentViewedAd, setCurrentViewedAd] = useState<IAd | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  // const { id } = useParams()
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    if (!isLoading) return;
    let data = document.getElementById("mobad_elements")?.innerHTML;
    data = data?.trim()
    if (data![0] == "\"")
      data = data?.substring(1)
    if (data![data!.length-1] == "\"")
      data = data?.substring(0,data!.length-1)
    // console.log(data)
    if (data === null) return;
    const ad = JSON.parse(data!) as IAd;
    // console.log(ad);

    setCurrentViewedAd(ad);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    console.log(currentViewedAd);
  }, [currentViewedAd]);

  return (
    <div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="App">
          {/* <h1>{queryParams.get('appid')}</h1> */}
          {currentViewedAd && (
            <Ad
              showBubbleOnWeb={currentViewedAd.showBubbleOnWeb}
              id={currentViewedAd.id}
              actionTokens={currentViewedAd.actionTokens}
              // pos={currentViewedAd.webStyle.screenPlacement}
              content={currentViewedAd.content}
            // webStyle={currentViewedAd.webStyle}
            />
          )}
        </div>
      )}
    </div>
  );
}
