import { useEffect, useState } from 'react';
import './Styles/index.css';
import IAd from './interface';

export default function Ad(props: IAd) {

    const [redirectClickUrl, setRedirectClickUrl] = useState("https://i-magineworks20.gotrackier.com/click?campaign_id=249&pub_id=28&p1={your-transaction-id}&source={your-sub-aff-id}")
    // const mediaBaseUrl = 'https://mobadserversdata.blob.core.windows.net/';
    // const mediaBaseUrl = props.content[0].baseUrl + "/";
    const mediaBaseUrl = props.content[0].baseUrl;
    // const actionBaseUrl = 'https://mobad.i-magineworks.com:';
    const actionBaseUrl = 'http://mobadtest.i-magineworks.com';
    const actionPath = 'api/v1/statisticService/action/register';

    const videoFormats = [
        "3g2",
        "3gp",
        "asf",
        "avi",
        "flv",
        "m4v",
        "mkv",
        "mov",
        "mp4",
        "mpeg",
        "mpg",
        "ogv",
        "rm",
        "swf",
        "vob",
        "webm",
        "wmv",
        "m2v",
        "ts",
        "mts",
        "mxf",
        "rmvb",
        "divx",
        "mpg4",
        "mpeg4",
        "mpe",
        "dv",
        "dvr-ms",
        "ogm",
        "qt",
        "rm",
        "yuv",
        "vcd",
        "svcd",
        "nsv",
        "amv",
        "dv",
        "f4v",
        "gifv",
        "h264",
        "m2ts",
        "m4p",
        "mng",
        "roq",
        "yuv",
        "xvid",
        "264",
        "265",
        "vp9",
        "avchd",
        "f4p",
        "flv",
        "h265",
        "hevc",
        "m2p",
        "mpeg2",
        "ogx",
        "ts",
        "x264",
        "x265",
        "dts",
        "dvb",
        "fli",
        "mlv",
        "nut",
        "oma",
        "r3d",
        "smv",
        "voc",
        "webm",
        "wtv",
        "m1v",
        "xavc",
        "xmv",
    ];


    // const style = {
    //     width: props.webStyle.width + 'px',
    //     height: props.webStyle.height + 'px'
    // }

    const _createIFrame = (attributes: any): HTMLIFrameElement => {
        let element: HTMLIFrameElement = document.createElement('iframe')
        let frameStyle = {
            width: '0px',
            height: '0px',
            position: 'absolute',
            border: 0
        }
        Object.assign(element.style, frameStyle)
        Object.assign(element, attributes)
        return element
    }

    const createActionUrl = (
        actionKey: string,
        adId: string,
        token: string,
        redirect?: string | null): string => {
        var queryData = {
            ak: actionKey,
            ci: adId,
            tk: token,
        } as any
        if (redirect !== null || redirect !== undefined) {
            queryData['r'] = redirect
        }
        var queries = new URLSearchParams(queryData).toString();
        var url = `${actionBaseUrl}/${actionPath}?${queries}`
        return url
    }

    // const registerAction = (
    //     actionKey: string,
    //     adId: string,
    //     token: string,
    //     redirect?: string) => {
    //     var url = createActionUrl(actionKey, adId, token, redirect)
    //     var frame = _createIFrame({ src: url })
    //     document.body.append(frame)
    // }

    // const onImageLoad = () => {
    //     registerAction("VIEW", props.id, props.actionTokens[0], undefined)
    // }

    // useEffect(() => {
    //     var url = createActionUrl("CLICK", props.id, props.actionTokens[2], props.content[0].content.url)
    //     setRedirectClickUrl(url)
    // }, [])

    const [fadeOut, setFadeOut] = useState(false);
    const [showad, setShowad] = useState(false);

    const handleThumbnailClick = () => {
        setFadeOut(true);
        setTimeout(() => {
        }, 500);
    };

    const handleTransitionEnd = () => {
        const thumbImage = document.getElementById("thumb");
        if (thumbImage)
            thumbImage.style.display = "none";
        setShowad(true)
    };

    const renderImageAd = (
        <a
            style={{
                width: "100%",
                height: "100%",
                cursor: "pointer"
            }}
            onClick={(event) => {
                event.preventDefault();
            }}
            href={redirectClickUrl}>
            {
                !videoFormats.includes(props.content[0].mediaPath.split(".")[props.content[0].mediaPath.split(".").length as any - 1]) ?
                    (<img
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                        onClick={() => {
                            window.open(redirectClickUrl, '_blank');
                            // window.open(props.content[0].content.url, '_blank');
                        }}
                        src={`${mediaBaseUrl}${props.content[0].mediaPath}`}
                    />) :
                    (<video
                        controls
                        autoPlay={false}
                        style={{
                            width: "100%",
                            height: "100%"
                        }}
                        onClick={(event) => { 
                            window.open(redirectClickUrl, '_blank');event.preventDefault(); }}
                    >
                        <source src={`${mediaBaseUrl}${props.content[0].mediaPath}`} type="video/mp4" />
                        Error Video
                    </video>)
            }

        </a>
    )

    return (
        <div className={"ad"}>
            {/* <div className="corner-buttons">
                <button className="ad-button" onClick={() => {
                    window.open(redirectClickUrl, '_blank');
                }}>Ad</button>
                <button onClick={() => {
                    const adElement = document.getElementsByClassName("ad")[0] as HTMLElement;
                    if (adElement) {
                        adElement.innerHTML = "<div class='powered'><h3>Powered by Mobad</h3></div>"
                    }
                }}>x</button>
            </div> */}
            <div id='imgss'>
                {!props.showBubbleOnWeb ? (
                    renderImageAd
                ) : (
                    <>
                        <img
                            id='thumb'
                            // onLoad={onImageLoad}
                            style={{
                                width: "20%",
                                cursor: "pointer",
                                opacity: fadeOut ? 0 : 1,
                                transition: "opacity 0.5s ease",
                            }}
                            onClick={handleThumbnailClick}
                            src={`${mediaBaseUrl}${props.content[0].thumbnail}`}
                            onTransitionEnd={handleTransitionEnd}
                        />
                        {/* Second Image */}
                        {showad && (
                            renderImageAd
                        )}
                    </>
                )}

            </div>

        </div >
    );


}